<ciphr-app-navigation *transloco="let t">
  <chr-button-icon
    privacy
    [icon]="selectSensitiveDataVisibility() ? 'eye-crossed' : 'eye'"
    data-test-id="sensitiveDataToggleButton"
    [attr.aria-label]="t('COMMON.SENSITIVE_DATA.ARIA_TOOGLE')"
    (chr-action)="togglePrivacyMode($event)"
  ></chr-button-icon>
</ciphr-app-navigation>

