export type NavigationItem = {
  application: string;
  fullName: string;
  items: SubNavItem[] | null;
  shortName: string;
  url: string | null;
};

export type SubNavItem = {
  items: SubNavItem[] | null;
  label: string;
  description?: string;
  type?: NavItemTypes;
  url: string | null;
};

export enum NavItemTypes {
  Divider = 'Divider',
  Item = 'Item',
}
