import { Directive, inject, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { TemplatePortal } from '@angular/cdk/portal';
import { PageHeaderService } from './page-header-service';

@Directive({
  selector: '[ciphrPageHeaderActionsArea]',
  standalone: true,
})
export class PageHeaderActionsAreaDirective implements OnInit, OnDestroy {
  private pageHeaderService = inject(PageHeaderService);
  private templateRef = inject(TemplateRef<unknown>);
  private viewContainerRef = inject(ViewContainerRef);

  ngOnInit(): void {
    setTimeout(() => {
      this.pageHeaderService.setActionsAreaPortal(new TemplatePortal<unknown>(this.templateRef, this.viewContainerRef));
    }, 0); // Use "setTimeout" to avoid ExpressionChangedAfterItHasBeenCheckedError
  }

  ngOnDestroy(): void {
    this.pageHeaderService.resetActionsPortal();
  }
}
