import { inject, Injectable } from '@angular/core';
import { USER_SESSION_API_CLIENT } from './user-session.provider';
import { DOCUMENT } from '@angular/common';
import { SsoSession } from './sso-session.inteface';
import { AUTH_PANEL_SERVICE } from '@ciphr/core/app-navigation/features';
import { SsoSessionEvent } from './sso-session-event.type';
import { toSignal } from '@angular/core/rxjs-interop';
import { BehaviorSubject, catchError, filter, map, Observable, of, share, switchMap, tap } from 'rxjs';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { UserSessionComponent } from './user-session.component';

@Injectable({ providedIn: 'root' })
export class UserSessionService {
  private httpClient = inject(USER_SESSION_API_CLIENT);
  private document = inject(DOCUMENT);
  private overlay = inject(Overlay);

  private readonly authPanelService = inject(AUTH_PANEL_SERVICE);
  private overlayRef!: OverlayRef;

  private _sessionEvent = new BehaviorSubject<SsoSessionEvent | null>(null);
  private readonly sessionEvent$ = this._sessionEvent.asObservable().pipe(
    filter(Boolean),
    tap((event) => {
      if (event && event.type !== 'session_expiring' && event.type !== 'session_expiring_elapsed')
        this.overlayRef && this.overlayRef.detach();
    }),
    tap((event) => event.type === 'session_expired' && this.logout()),
    share(),
  );

  private readonly ssoSessionExpiredTime$ = this.sessionEvent$.pipe(
    filter((event) => event && event.type === 'session_expiring'),
    tap(() => this.displayOverlay()),
  );

  private readonly ssoSessionElapsedExpiredTime$ = this.sessionEvent$.pipe(
    filter((event) => event && event.type === 'session_expiring_elapsed'),
    map((event) => event.payload.expiresInSeconds * 1000),
  );

  private readonly remainingTime$ = this.ssoSessionExpiredTime$.pipe(switchMap(() => this.ssoSessionElapsedExpiredTime$));

  remainingTime = toSignal(this.remainingTime$);

  private registerSsoSessionScript(scriptContent: string): void {
    const { defaultView } = this.document;
    const ssoSession = defaultView ? ((defaultView as Record<string, any>)['ssosession'] as SsoSession) : null;
    if (!ssoSession) {
      this.addScriptToDOM(scriptContent);
      this.registerSessionListeners();
    }
  }

  private addScriptToDOM(scriptContent: string): void {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = scriptContent;
    document.body.appendChild(script);
  }

  private registerSessionListeners(): void {
    window.addEventListener('session_expiring_elapsed', (event: Event) =>
      this._sessionEvent.next({ type: 'session_expiring_elapsed', payload: (event as any).detail }),
    );

    window.addEventListener('session_expiring', (event: Event) =>
      this._sessionEvent.next({ type: 'session_expiring', payload: (event as any).detail }),
    );

    window.addEventListener('session_refreshed', () => this._sessionEvent.next({ type: 'session_refreshed' }));
    window.addEventListener('session_expired', () => this._sessionEvent.next({ type: 'session_expired' }));
  }

  fetchUserSessionScript(): Observable<string | null> {
    return this.httpClient.get('/user-session/script', { responseType: 'text' }).pipe(
      tap((scriptContent) => this.registerSsoSessionScript(scriptContent)),
      catchError(() => of(null)),
    );
  }

  refreshSession(): void {
    const { defaultView } = this.document;
    const ssoSession = defaultView ? ((defaultView as Record<string, any>)['ssosession'] as SsoSession) : null;

    if (!ssoSession) {
      throw 'Session has not been initialized';
    }

    ssoSession.keepalive();
  }

  logout(): void {
    this.authPanelService.logOut();
  }

  displayOverlay() {
    this.overlayRef = this.overlay.create({
      hasBackdrop: false,
      width: '100%',
      maxWidth: '540px',
      positionStrategy: this.overlay.position().global().bottom('28px').centerHorizontally(),
    });
    this.overlayRef.attach(new ComponentPortal(UserSessionComponent));
  }
}
