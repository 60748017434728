<chr-toast *transloco="let t" palette="informative" [unclosable]="true" [title]="t('COMMON.USER_SESSION.DIALOG.TITLE')" data-test-id="sessionExpirationToast">
  <span
    >{{ t("COMMON.USER_SESSION.DIALOG.DESCRIPTION") }} {{ remainingTime() | date: "mm:ss" }}
    {{ t("COMMON.USER_SESSION.DIALOG.TIME_UNIT") }}</span
  >
  <chr-button
    slot="footer"
    palette="informative"
    variant="secondary"
    size="s"
    data-test-id="remainLoggedIn"
    (click)="refreshSession()"
    [label]="t('COMMON.USER_SESSION.DIALOG.ACTIONS.REMAIN_LOGGED')"
  />
  <chr-button
    slot="footer"
    palette="informative"
    variant="invisible"
    size="s"
    data-test-id="sessionLogOut"
    (click)="logout()"
    [label]="t('COMMON.USER_SESSION.DIALOG.ACTIONS.LOG_OUT')"
  />
</chr-toast>
